import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CreateDataOnServer, GetDataListFromServer } from "../lib/apis";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  AGENT_PAYMENT_METHOD_LIST_API,
  AGENT_TRANSACTION_CREATE_API,
  USER_GET_LIT_API,
} from "../lib/config";
import { QUERY_AGENT_PAYMENT_METHOD, QUERY_USER } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import UserForm from "../components/user/form";
import { PlusOutlined } from "@ant-design/icons";
import UserDetail from "../components/user/detail";
import { updateUser } from "../store/user";

const { Text } = Typography;

export default function UsersPage() {
  const {
    permissions,
    access_token,
    ui_size,
    first_time_promo,
    id,
    user_noti,
    ...agent
  } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modelLoading, setModelLoading] = useState(false);
  const [agentPayments, setAgentPayments] = useState([]);
  const disaptch = useDispatch();
  const [data, setData] = useState(false);
  const [create, setCreate] = useState("");
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    user_code: "",
    is_deleted: "",
    payment_account: "",
    is_locked: "",
    order_by: "created_at",
    order_type: "DESC",
  });
  const [detail, setDetail] = useState(false);

  async function getAgentPayments(page_number, per_page) {
    await GetDataListFromServer(
      AGENT_PAYMENT_METHOD_LIST_API,
      queryHandler(
        {
          name: "",
          is_deleted: "false",
          order_by: "created_at",
          order_type: "DESC",
        },
        QUERY_AGENT_PAYMENT_METHOD,
        {
          page_number,
          per_page,
        }
      ),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setAgentPayments(result);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getAgentPayments(1, 100);
  }, []);

  async function getDataList(page_number, per_page, updateFilter) {
    setLoading(true);
    await GetDataListFromServer(
      USER_GET_LIT_API,
      queryHandler(updateFilter ? updateFilter : filter, QUERY_USER, {
        page_number,
        per_page,
      }),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        updateFilter && setFilter(updateFilter);
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    if (user_noti) {
      getDataList(pagin.page_number, pagin.per_page);
    }
  }, [user_noti]);

  function closeDrawer(key) {
    setData(false);
    setCreate("");
    setShowForm(false);
    setDetail(false);
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "User Code",
      dataIndex: "user_code",
    },
    {
      title: "Login Name",
      dataIndex: "payment_account",
    },
    {
      title: "Point",
      align: "right",
      dataIndex: ["wallet", "point"],
      render: (point) => point || 0,
    },
    {
      title: "Promo Points",
      align: "right",
      dataIndex: ["wallet", "promotion_point"],
      render: (point) => point || 0,
    },
    {
      title: "Is Locked",
      dataIndex: "is_locked",
      align: "center",
      render: (is_locked) => (
        <Tag color={is_locked ? "warning" : "success"}>
          {is_locked ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Button
              size={ui_size}
              type="primary"
              disabled={user.is_deleted}
              onClick={() => {
                setData(user);
                setCreate("deposit");
              }}
            >
              Create Deposit
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size={ui_size}
              disabled={user.is_deleted}
              onClick={() => {
                setData(user);
                setCreate("withdraw");
              }}
            >
              Create Withdraw
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              size={ui_size}
              onClick={() => setDetail(user)}
            >
              Detail
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  async function createDepositOrWithdraw(value) {
    const target = value.payment_id
      ? agentPayments.find((paym) => paym.id === value.payment_id)
      : false;
    setModelLoading(true);
    await CreateDataOnServer(
      AGENT_TRANSACTION_CREATE_API,
      {
        ...value,
        user_id: data.id,
        type: create,
        user_points: data.wallet?.point || 0,
        transaction_type: target ? target.account_type : value.payment_type,
      },
      access_token
    )
      .then(({ data: agentData }) => {
        disaptch(
          updateUser({
            ...agent,
            access_token,
            permissions,
            first_time_promo,
            id,
            ui_size,
            user_noti,
            agent_points: agentData.agent_points,
          })
        );
        setModelLoading(false);
        closeDrawer(true);
      })
      .catch((error) => {
        setModelLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  function clearTransaction() {
    getDataList(pagin.page_number, pagin.per_page, {
      user_code: "",
      is_deleted: "false",
      payment_account: "",
      is_locked: "",
      order_by: "created_at",
      order_type: "DESC",
    });
  }

  return (
    <>
      <Row
        gutter={[0, 16]}
        justify={"space-between"}
        style={{ minHeight: "85vh", overflowX: "hidden" }}
      >
        <Col span={24}>
          <Row gutter={[12, 12]} style={{ marginBottom: "12px" }}>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>User Code</Text>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) =>
                      setFilter({ ...filter, user_code: e.target.value })
                    }
                    value={filter.user_code}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Payment Account</Text>
                </Col>
                <Col span={12}>
                  <Input
                    onChange={(e) =>
                      setFilter({ ...filter, payment_account: e.target.value })
                    }
                    value={filter.payment_account}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={12} justify={"end"}>
                <Col>
                  <Button type="primary" onClick={() => clearTransaction()}>
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() =>
                      getDataList(pagin.page_number, pagin.per_page)
                    }
                  >
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button
                    size={ui_size}
                    type="primary"
                    onClick={() => setShowForm(true)}
                    icon={<PlusOutlined />}
                  >
                    New User
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={"New User"}
        destroyOnClose
        placement="right"
        width={"800px"}
      >
        <UserForm edit={false} closeDrawer={closeDrawer} />
      </Drawer>
      <Drawer
        title="User Detail"
        onClose={() => setDetail(false)}
        styles={{
          body: { margin: 0, padding: "0" },
          header: { borderBottom: "0px" },
        }}
        placement="right"
        width={"90vw"}
        open={!!detail}
        key={"user_detail_drawer"}
        destroyOnClose={true}
      >
        <UserDetail detail={detail} closeDrawer={closeDrawer} />
      </Drawer>
      <Modal
        open={create !== ""}
        onCancel={() => setCreate("")}
        destroyOnClose={true}
        footer={null}
        title={create}
      >
        <Row align={"middle"} justify={"end"} gutter={[12, 24]}>
          <Col span={12}>Agent Points = {agent.agent_points}</Col>
          <Col span={12}>User Points = {data.wallet?.point || 0}</Col>
          <Col span={24}>
            <Form
              onFinish={createDepositOrWithdraw}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              name="create_form"
            >
              <Form.Item
                label="Points"
                name="points"
                rules={[{ required: true }]}
              >
                <InputNumber
                  value={create.points}
                  max={
                    create === "deposit"
                      ? agent.agent_points
                      : data.wallet?.point || 0
                  }
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {create === "deposit" ? (
                <Form.Item
                  label="payment Account"
                  name="payment_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={agentPayments.map((value) => ({
                      label: value.name,
                      value: value.id,
                    }))}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label="Payment Type"
                  name="payment_type"
                  rules={[{ required: true }]}
                >
                  <Select
                    options={[
                      { label: "KPAY", value: "KPAY" },
                      { label: "WaVE", value: "VAVE" },
                    ]}
                  />
                </Form.Item>
              )}
              <Form.Item label="note" name="note">
                <Input />
              </Form.Item>
              <Row>
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      loading={modelLoading}
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
