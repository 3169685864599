const QUERY_ACCESS_PERMISSION = {
  filter: [{ value: "", name: "name", key: "ILike", type: "string" }],
  order: ["created_at"],
};

const QUERY_CMS_USER = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "string" },
    { value: "", name: "id", key: "Equal", type: "string" },
    { value: "", name: "is_locked", key: "Equal", type: "boolean" },
    { value: "false", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "is_locked"],
};

const QUERY_CMS_AGENTS = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "string" },
    { value: "", name: "agent_code", key: "Equal", type: "string" },
    { value: "", name: "id", key: "Equal", type: "string" },
    { value: "", name: "is_locked", key: "Equal", type: "boolean" },
    { value: "", name: "is_blocked", key: "Equal", type: "boolean" },
    { value: "false", name: "is_deleted", key: "Equal", type: "boolean" },
    { value: "false", name: "is_api_only", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "is_blocked", "is_locked", "agent_points"],
};

const QUERY_CMS_PAYMENT_METHOD = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "stirng" },
    { value: "", name: "account_type", key: "Equal", type: "string" },
    { value: "", name: "account_no", key: "Equal", type: "string" },
    { value: "", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at"],
};

const QUERY_CMS_TRANSACTION = {
  filter: [
    { value: "", name: "payment_method_id", key: "Equal", type: "string" },
    { value: "", name: "agent_id", key: "Equal", type: "string" },
  ],
  order: ["created_at", "points", "amount"],
};

const QUERY_AGENT_PAYMENT_METHOD = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "string" },
    { value: "", name: "agent_id", key: "Equal", type: "Id" },
    { value: "false", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at"],
};

const QUERY_AGENT_PROMOTION_DEPOSIT = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "string" },
    { value: "", name: "agent_id", key: "Equal", type: "Id" },
    { value: "false", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at"],
};

const QUERY_AGENT_PROMOTION_SPECIAL = {
  filter: [
    { value: "", name: "name", key: "ILike", type: "string" },
    { value: "", name: "agent_id", key: "Equal", type: "Id" },
  ],
  order: ["created_at"],
};

const QUERY_UTILITY_AVATAR = {
  filter: [{ value: "", name: "name", key: "ILike", type: "string" }],
  order: ["created_at"],
};

const QUERY_AGENT_TRANSACTION = {
  filter: [
    { value: "", name: "user_id", key: "Equal", type: "string" },
    { value: "", name: "transaction_id", key: "Equal", type: "string" },
    { value: "", name: "transaction_type", key: "Equal", type: "string" },
    { value: "", name: "payment_type", key: "Equal", type: "string" },
    { value: "", name: "payment_id", key: "Equal", type: "string" },
    { value: "", name: "start_date", key: "Equal", type: "string" },
    { value: "", name: "end_date", key: "Equal", type: "string" },
    { value: "", name: "get_total", key: "Equal", type: "boolean" },
    { value: "", name: "is_approve", key: "Equal", type: "boolean" },
    { value: "", name: "is_reject", key: "Equal", type: "boolean" },
  ],
  order: ["updated_at", "created_at"],
};

const QUERY_USER = {
  filter: [
    { value: "", name: "user_code", key: "Equal", type: "string" },
    { value: "", name: "payment_account", key: "Equal", type: "string" },
    { value: "false", name: "is_deleted", key: "Equal", type: "boolean" },
    { value: "", name: "is_locked", key: "Equal", type: "boolean" },
    { value: "", name: "agent_id", key: "Equal", type: "string" },
  ],
  order: ["created_at", "is_blocked", "is_locked"],
};

const QUERY_GAME_TWOD_USER_LOGS = {
  filter: [
    { value: "", name: "target_time", key: "Equal", type: "string" },
    { value: "", name: "target_date", key: "Equal", type: "string" },
    { value: "", name: "win_lose", key: "Equal", type: "boolean" },
    { value: "", name: "is_finish", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "is_finish"],
};

const QUERY_GAME_THREED_USER_LOGS = {
  filter: [
    { value: "", name: "target_date", key: "Equal", type: "string" },
    { value: "", name: "win_lose", key: "Equal", type: "boolean" },
    { value: "", name: "is_finish", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "is_finish"],
};

const QUERY_TWOD_THREED_AGENT = {
  filter: [
    { value: "", name: "agent_id", key: "Equal", type: "stirng" },
    { value: "", name: "agent_code", key: "Equla", type: "stirng" },
  ],
  order: ["created_at", "updated_at"],
};

const QUERY_BALL_AGENT = {
  filter: [
    { value: "", name: "agent_id", key: "Equal", type: "stirng" },
    { value: "", name: "agent_code", key: "Equla", type: "stirng" },
  ],
  order: ["created_at", "updated_at"],
};

const QUERY_BALL_TEAM = {
  filter: [{ value: "", name: "is_deleted", key: "Equal", type: "boolean" }],
  order: ["created_at", "updated_at"],
};

const QUERY_BALL_LEAGUE = {
  filter: [{ value: "", name: "is_deleted", key: "Equal", type: "boolean" }],
  order: ["created_at", "updated_at"],
};

const QUERY_BALL_MATCH = {
  filter: [
    { value: "", name: "team_name_en", key: "ILike", type: "stirng" },
    { value: "", name: "league_id", key: "Equal", type: "stirng" },
    { value: "", name: "is_finish", key: "Equal", type: "boolean" },
    { value: "", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "updated_at"],
};

const QUERY_TWOD_THREED_HOLIDAY = {
  filter: [],
  order: ["created_at", "holiday"],
};

const QUERY_TWOD_THREED_3D_ROUND = {
  filter: [
    { value: "", name: "is_finish", key: "Equal", type: "boolean" },
    { value: "", name: "is_deleted", key: "Equal", type: "boolean" },
  ],
  order: ["created_at", "target_date"],
};

const QUERY_USER_TWOD_LOGS = {
  filter: [
    { value: "", name: "win_lose", key: "Equal", type: "boolean" },
    { value: "", name: "is_finish", key: "Equal", type: "boolean" },
    { value: "", name: "target_time", key: "Equal", type: "string" },
    { value: "", name: "target_date", key: "Equal", type: "string" },
  ],
  order: [],
};

const QUERY_PP_AGENT = {
  filter: [
    { value: "", name: "agent_id", key: "Equal", type: "stirng" },
    { value: "", name: "agent_code", key: "Equla", type: "stirng" },
  ],
  order: ["created_at", "updated_at"],
};

const QUERY_PP_USER_LOGS = {
  filter: [
    { value: "", name: "user_id", key: "Equal", type: "string" },
    { value: "", name: "type", key: "Equal", type: "string" },
    { value: "", name: "target_date", key: "Equal", type: "string" },
    { value: "", name: "game_id", key: "Equal", type: "string" },
  ],

  order: [],
};

const QUERY_SHAN_USER_LOGS = {
  filter: [
    { value: "", name: "user_id", key: "Equal", type: "string" },
    { value: "", name: "target_date", key: "Equal", type: "string" },
  ],

  order: [],
};

export {
  QUERY_ACCESS_PERMISSION,
  QUERY_CMS_USER,
  QUERY_CMS_AGENTS,
  QUERY_CMS_PAYMENT_METHOD,
  QUERY_CMS_TRANSACTION,
  QUERY_AGENT_PAYMENT_METHOD,
  QUERY_AGENT_PROMOTION_DEPOSIT,
  QUERY_AGENT_PROMOTION_SPECIAL,
  QUERY_UTILITY_AVATAR,
  QUERY_AGENT_TRANSACTION,
  QUERY_USER,
  QUERY_TWOD_THREED_AGENT,
  QUERY_BALL_AGENT,
  QUERY_BALL_TEAM,
  QUERY_BALL_LEAGUE,
  QUERY_BALL_MATCH,
  QUERY_TWOD_THREED_3D_ROUND,
  QUERY_TWOD_THREED_HOLIDAY,
  QUERY_GAME_TWOD_USER_LOGS,
  QUERY_GAME_THREED_USER_LOGS,
  QUERY_USER_TWOD_LOGS,
  QUERY_PP_AGENT,
  QUERY_PP_USER_LOGS,
  QUERY_SHAN_USER_LOGS,
};
