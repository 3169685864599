import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CreateDataOnServer,
  GetDataListFromServer,
  UpdateSingleDataOnServer,
} from "../lib/apis";
import {
  Button,
  Col,
  Drawer,
  Form,
  Modal,
  Row,
  Select,
  Table,
  Typography,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  GAME_THREED_FINISH_API,
  GAME_TWOD_THREED_AGENT_UPDATE_API,
  GAME_TWOD_THREED_THREED_ONGOING_LIST_API,
} from "../lib/config";
import { QUERY_TWOD_THREED_3D_ROUND } from "../lib/query_objects";
import { queryHandler } from "../lib/globle_functions";
import ThreedOngoingForm from "../components/game_twod_threed/threed_ongoing_form";
import dayjs from "dayjs";

const { Text } = Typography;

export default function GameThreedOngoing() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [list, setList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [pagin, setPagin] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });
  const [filter, setFilter] = useState({
    is_finish: "",
    is_deleted: "false",
    order_by: "target_date",
    order_type: "DESC",
  });
  const [finishNumber, setFinishNumber] = useState(false);
  const [showModel, setShowModel] = useState(false);

  async function finishHandler(value) {
    setLoading(true);
    await CreateDataOnServer(GAME_THREED_FINISH_API, {id:edit.id,win_number:value.win_number}, access_token)
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
        setFinishNumber(false);
        setEdit(false)
        setShowModel(false);
      })
      .catch((error) => {
        message.error(error.message);
        setLoading(false);
      });
  }

  async function getDataList(page_number, per_page) {
    setLoading(true);
    await GetDataListFromServer(
      GAME_TWOD_THREED_THREED_ONGOING_LIST_API,
      queryHandler(filter, QUERY_TWOD_THREED_3D_ROUND, pagin),
      access_token
    )
      .then(({ data: { data: result, total } }) => {
        setLoading(false);
        setPagin({ ...pagin, total, page_number, per_page });
        setList(result);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function unlockUser(id) {
    setLoading(true);
    await UpdateSingleDataOnServer(
      GAME_TWOD_THREED_AGENT_UPDATE_API,
      id,
      access_token
    )
      .then(() => {
        getDataList(pagin.page_number, pagin.per_page);
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  useEffect(() => {
    getDataList(pagin.page_number, pagin.per_page);
  }, []);

  const columns = [
    {
      title: "Release Date",
      dataIndex: "target_date",
      align: "center",
      render: (target_date) => dayjs(target_date).format("YYYY-MM-DD"),
    },
    {
      title: "Bit Start Date",
      dataIndex: "bit_start_date",
      align: "center",
      render: (bit_start_date) => dayjs(bit_start_date).format("YYYY-MM-DD"),
    },
    {
      title: "Bit End Date",
      dataIndex: "bit_end_date",
      align: "center",
      render: (bit_end_date) => dayjs(bit_end_date).format("YYYY-MM-DD"),
    },
    {
      title: "Win Number",
      dataIndex: "win_number",
      align: "center",
      render: (_, data) => (
        <Text>{data.is_finish ? data.win_number : "---"}</Text>
      ),
    },
    {
      title: "",
      render: (_, user) => (
        <Row gutter={16} justify={"end"}>
          <Col>
            <Button
              size={ui_size}
              style={{ width: "100px" }}
              disabled={
                !permissions.includes("TWOD_THREED_CREATE") || user.is_finish
              }
              type="primary"
              onClick={() => {
                setEdit(user)
                setShowModel(true);
              }}
            >
              Finish
            </Button>
          </Col>
          <Col>
            <Button
              size={ui_size}
              style={{ width: "100px" }}
              disabled={
                !permissions.includes("TWOD_THREED_CREATE") || user.is_finish
              }
              type="primary"
              onClick={() => {
                setEdit(user);
                setShowForm(true);
              }}
            >
              Edit
            </Button>
          </Col>
        </Row>
      ),
    },
  ];

  function closeDrawer(key) {
    if (key) {
      getDataList(pagin.page_number, pagin.per_page);
    }
    setEdit(false);
    setShowForm(false);
  }

  return (
    <>
      <Row gutter={[0, 16]} justify={"end"}>
        <Col>
          {permissions.includes("TWOD_THREED_CREATE") && (
            <Button
              size={ui_size}
              type="primary"
              onClick={() => setShowForm(true)}
              icon={<PlusOutlined />}
            >
              New 3D Release Date
            </Button>
          )}
        </Col>
        <Col span={24}>
          <Table
            size={ui_size}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={list}
            loading={loading}
            pagination={{
              total: pagin.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getDataList(pageNumber, perPage);
              },
            }}
          />
        </Col>
      </Row>
      <Drawer
        open={showForm}
        onClose={() => closeDrawer()}
        title={edit ? "Edit Release Date" : "Create Release Date"}
        destroyOnClose
        placement="right"
        width={"500px"}
      >
        <ThreedOngoingForm edit={edit} closeDrawer={closeDrawer} />
      </Drawer>
      <Modal
        title="3D finish"
        open={showModel}
        onCancel={() => setShowModel(false)}
        footer={null}
      >
        <Form name="threed_finish_form" onFinish={finishHandler}>
          <Form.Item
            name="win_number"
            label="Win Number"
            rules={[{ required: true }]}
          >
            <Select
            showSearch
              options={[...new Array(1000)].map((_, index) => ({
                value: index < 10
                ? `00${index}`
                : index < 100
                ? `0${index}`
                : `${index}`,
                label:
                  index < 10
                    ? `00${index}`
                    : index < 100
                    ? `0${index}`
                    : `${index}`,
              }))}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row justify={'end'}>
            <Col>
              <Form.Item>
                <Button loading={loading} htmlType="submit" type="primary">
                  Finish
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
