import { useDispatch, useSelector } from 'react-redux';
import { MENU_ITEMS } from '../lib/menu_items';
import { Badge, Col, Layout, Menu, Row, Typography, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import { AGENT_TRANSACTION_NOTI_API } from '../lib/config';
import { BellTwoTone } from '@ant-design/icons';
import { updateUser } from '../store/user';
import noti_sound from '../lib/audio/beep-02.wav';

const { Text } = Typography;

export default function LayoutSideBar() {
  const [collapse, setCollapse] = useState(false);
  const [selected, setSelected] = useState('1');
  const { pathname } = useLocation();
  const { permissions, ui_size, id, transaction_noti, user_noti, ...user } =
    useSelector((state) => state.user);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();

  function playSound() {
    const audio = new Audio(noti_sound);
    audio.muted = false;
    audio.play();
  }

  useEffect(() => {
    if (permissions.includes('IS_AGENT') && id) {
      const notiIntefval = setInterval(async () => {
        await axios
          .get(`${AGENT_TRANSACTION_NOTI_API}/${id}`)
          .then(({ data }) => {
            dispatch(
              updateUser({
                ...user,
                permissions,
                ui_size,
                id,
                transaction_noti: data.noti,
                user_noti: data.user,
              })
            );
          })
          .catch((error) => console.log(error));
      }, 2000);

      return () => clearInterval(notiIntefval);
    }
  }, []);

  useEffect(() => {
    let links = [];

    for (let i of MENU_ITEMS) {
      if (i.key !== '1' && i.key !== '200') {
        if (i.children) {
          for (let { link, key } of i.children) {
            links = [...links, { link, key }];
          }
        }
      }

      const selected = links.filter((value) => value.link === pathname);
      if (selected.length > 0) {
        setSelected(selected[0].key);
      } else {
        switch (true) {
          case pathname === '/settings':
            setSelected('200');
            break;
          case pathname === '/payment_method':
            setSelected('5');
            break;
          case pathname === '/promotion':
            setSelected('6');
            break;
          case pathname === '/promotion_special':
            setSelected('7');
            break;
          case pathname === '/subscription':
            setSelected('8');
            break;
          case pathname === '/transaction':
            setSelected('10');
            break;
          case pathname === '/users':
            setSelected('11');
            break;
          case pathname === '/twod':
            setSelected('15');
            break;
          case pathname === '/threed':
            setSelected('16');
            break;
          case pathname === '/ball_ongoing_deposit':
            setSelected('17');
            break;
          case pathname === '/goal_forecast':
            setSelected('18');
            break;
          case pathname === '/maung_goal_forecast':
            setSelected('19');
            break;
          default:
            setSelected('1');
            break;
        }
      }
    }
  }, [pathname, transaction_noti]);

  function menuHandler() {
    let accessMenu = [];

    for (let i of MENU_ITEMS) {
      if (i.key === '1' || i.key === '200') {
        accessMenu = [...accessMenu, i];
      } else if (
        permissions.filter((value) => i.permission?.includes(value)).length > 0
      ) {
        let children = [];
        if (i.children) {
          for (let c of i.children) {
            if (
              permissions.filter((value) => c.permission?.includes(value))
                .length > 0
            ) {
              children = [
                ...children,
                {
                  key: c.key,
                  label: c.label,
                },
              ];
            }
          }
        }

        accessMenu = [
          ...accessMenu,
          children.length > 0
            ? {
                key: i.key,
                icon: i.icon,
                label: i.label,
                children,
              }
            : {
                key: i.key,
                icon: i.icon,
                label:
                  i.noti && i.noti === 'transaction' && transaction_noti ? (
                    <Row justify={'space-between'}>
                      {playSound()}
                      <Col>{i.label}</Col>
                      <Col>
                        <Badge count={<BellTwoTone />} />
                      </Col>
                    </Row>
                  ) : i.noti && i.noti === 'users' && user_noti ? (
                    <Row justify={'space-between'}>
                      <Col>{i.label}</Col>
                      <Col>
                        <Badge count={<BellTwoTone />} />
                      </Col>
                    </Row>
                  ) : (
                    i.label
                  ),
              },
        ];
      }
    }

    return accessMenu;
  }

  return (
    <Layout.Sider
      collapsible
      collapsed={collapse}
      onCollapse={(value) => setCollapse(value)}
      style={{ background: colorBgContainer }}
    >
      <div style={{ height: '64px' }}>
        <Row justify={'center'} align={'middle'} style={{ height: '100%' }}>
          <Col>
            <Text style={{ fontWeight: 700, fontSize: '1.3rem' }}>
              {permissions.includes('IS_AGENT') ? 'AGENT' : 'ADMIN'}
            </Text>
          </Col>
        </Row>
      </div>
      <div
        style={{
          height: 'calc(100vh - 110px)',
          overflowX: 'hidden',
          scrollbarWidth: 'none',
        }}
      >
        <Menu
          size={ui_size}
          style={{ minHeight: '100%' }}
          mode="inline"
          selectedKeys={selected}
          items={menuHandler()}
        />
      </div>
    </Layout.Sider>
  );
}
