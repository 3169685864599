const SERVER_API = process.env.REACT_APP_SERVER_API;

const CMS_USER_LOGIN_NAME_CHECK = `${SERVER_API}/v1/cms-user/login_name_check`;
const CMS_LOGIN_API = `${SERVER_API}/v1/login/cms_user`;
const CMS_USER_CREATE = `${SERVER_API}/v1/cms-user/create`;
const CMS_USER_GET_LIST = `${SERVER_API}/v1/cms-user/list`;
const CMS_USER_EDIT_API = `${SERVER_API}/v1/cms-user/edit`;
const CMS_USER_DELETE = `${SERVER_API}/v1/cms-user/delete`;
const CMS_USER_UNLOCK_API = `${SERVER_API}/v1/cms-user/unlock`;
const CMS_USER_PASSWORD_RESET = `${SERVER_API}/v1/cms-user/credential_reset`;
const CMS_PASSWORD_RESET = `${SERVER_API}/v1/cms-user/password_reset`;
const CMS_THEME_RESET_API = `${SERVER_API}/v1/cms-user/theme_change`;

const CMS_ACCESS_LEVEL_API = `${SERVER_API}/v1/cms-user/access_list`;
const CMS_SCCESS_LEVEL_CREATE_API = `${SERVER_API}/v1/cms-user/access/create`;
const CMS_ACCESS_LEVEL_UPDATE_API = `${SERVER_API}/v1/cms-user/access/edit`;
const CMS_ACCESS_LEVEL_DELETE_API = `${SERVER_API}/v1/cms-user/access/delete`;

const CMS_AGENT_CREATE_API = `${SERVER_API}/v1/agents/create`;
const CMS_AGENT_LOGIN_API = `${SERVER_API}/v1/login/agent`;
const CMS_AGENT_LOGIN_NAME_CHECK_API = `${SERVER_API}/v1/agents/login_name_check`;
const CMS_AGENT_AGENT_CODE_CHECK_API = `${SERVER_API}/v1/agents/agent_code_check`;
const CMS_AGENT_LIST_API = `${SERVER_API}/v1/agents/list`;
const CMS_AGENT_EDIT_API = `${SERVER_API}/v1/agents/edit`;
const CMS_AGENT_UNLOCK_API = `${SERVER_API}/v1/agents/unlock`;
const CMS_AGENT_DELETE_API = `${SERVER_API}/v1/agents/delete`;
const CMS_AGENT_CREDENTIAL_RESET_API = `${SERVER_API}/v1/agents/credential_reset`;
const CMS_AGENT_PASSWORD_RESET_API = `${SERVER_API}/v1/agents/password_reset`;
const CMS_AGENT_THEME_CHANGE_API = `${SERVER_API}/v1/agents/theme_change`;
const CMS_AGENT_BLOCK_API = `${SERVER_API}/v1/agents/block`;

const CMS_PAYMENT_METHOD_LIST_API = `${SERVER_API}/v1/subscription/payment`;
const CMS_PAYMENT_METHOD_CREATE_API = `${SERVER_API}/v1/subscription/payment/create`;
const CMS_PAYMENT_METHOD_DELETE_API = `${SERVER_API}/v1/subscription/payment/delete`;

const CMS_TRANSACTION_LIST_API = `${SERVER_API}/v1/subscription/transaction`;
const CMS_TRANSACTION_CREATE_API = `${SERVER_API}/v1/subscription/transaction/create`;

const UPLOAD_API = `${SERVER_API}/v1/file/image/upload`;

const AGENT_PAYMENT_METHOD_LIST_API = `${SERVER_API}/v1/agents/payment_methods`;
const AGENT_PAYMENT_METHOD_CREATE_API = `${SERVER_API}/v1/agents/payment_methods/create`;
const AGENT_PAYMENT_METHOD_EDIT_API = `${SERVER_API}/v1/agents/payment_methods/edit`;
const AGENT_PAYMENT_METHOD_DELETE_API = `${SERVER_API}/v1/agents/payment_methods/delete`;

const AGENT_CREATE_USER_PROMO_API = `${SERVER_API}/v1/agents/update_create_user_promo`;

const AGENT_PROMOTION_LIST_API = `${SERVER_API}/v1/agents/promotion_deposit`;
const AGENT_PROMOTION_CREATE_API = `${SERVER_API}/v1/agents/promotion_deposit/create`;
const AGENT_PROMOTION_EDIT_API = `${SERVER_API}/v1/agents/promotion_deposit/edit`;
const AGENT_PROMOTION_DELETE_API = `${SERVER_API}/v1/agents/promotion_deposit/delete`;

const AGENT_PROMOTION_SPECIAL_LIST_API = `${SERVER_API}/v1/agents/promotion_special`;
const AGENT_PROMOTION_SPECIAL_CREATE_API = `${SERVER_API}/v1/agents/promotion_special/create`;

const AGENT_SUBSCRIPTION_LIST_API = `${SERVER_API}/v1/subscription/agent_subscription`;

const UTILITY_AVATAR_LIST_API = `${SERVER_API}/v1/utility/avatar_list`;
const UTILITY_AVATAR_CREATE_API = `${SERVER_API}/v1/utility/create_avatar`;
const UTILITY_AVATAR_DELETE_API = `${SERVER_API}/v1/utility/delete_avatar`;

const AGENT_TRANSACTION_LIST_API = `${SERVER_API}/v1/agents/user_transaction`;
const AGENT_TRANSACTION_NOTI_API = `${SERVER_API}/v1/agents/transaction_check`;
const AGENT_TRANSACTION_USER_API = `${SERVER_API}/v1/agents/user_transaction`;
const AGENT_TRANSACTION_CREATE_API = `${SERVER_API}/v1/agents/create_transaciton`;

const USER_GET_LIT_API = `${SERVER_API}/v1/agents/agent_users_list`;
const USER_CREATE_API = `${SERVER_API}/v1/agents/agent_user_create`;
const USER_LOGIN_NAME_CHECK_APi = `${SERVER_API}/v1/agents/agent_user_login_name_check`;
const USER_EDIT_API = `${SERVER_API}/v1/agents/agent_user_edit`;
const USER_PASSWORD_RESET_API = `${SERVER_API}/v1/agents/user_password_reset`;
const USER_PAYMENT_COUNTER_RESET_API = `${SERVER_API}/v1/agents/user_payment_update_counter_reset`;
const USER_UNLOCK_API = `${SERVER_API}/v1/agents/user_unlocked`;
const USER_DELETE_API = `${SERVER_API}/v1/agents/user_delete`;
const USER_TRANSACTION_UPDATE = `${SERVER_API}/v1/agents/user_transaction_update`;

const GAME_AGENT_GET_LIST = `${SERVER_API}/v1/games/agent_list`;

const GAME_TWOD_THREED_AGENT_LIST_API = `${SERVER_API}/v1/games/two_three_agent_list`;
const GAME_TWOD_THREED_AGENT_CREATE_API = `${SERVER_API}/v1/games/two_three_agent_create`;
const GAME_TWOD_THREED_AGENT_UPDATE_API = `${SERVER_API}/v1/games/two_three_agent_update`;

const GAME_TWOD_THREED_THREED_ONGOING_LIST_API = `${SERVER_API}/v1/games/threed_admin_list`;
const GAME_TWOD_THREED_THREED_ONGOING_CREATE_API = `${SERVER_API}/v1/games/threed_admin_create`;
const GAME_TWOD_THREED_THREED_ONGOING_UPDATE_API = `${SERVER_API}/v1/games/threed_admin_update`;
const GAME_TWOD_THREED_THREED_ONGOING_DELETE_API = `${SERVER_API}/v1/games/threed_admin_delete`;
const GAME_TWOD_THREED_THREED_USER_LIST_APi = `${SERVER_API}/v1/games/agent_threed_user`;
const GAME_TWOD_THREED_TWOD_USER_LIST_APi = `${SERVER_API}/v1/games/agent_twod_user`;

const GAME_TWOD_THREED_TWOD_ADMIN_LIST_API = `${SERVER_API}/v1/games/twod_admin_list`;
const GAME_TWOD_THREED_TWOD_ADMIN_CREATE_API = `${SERVER_API}/v1/games/twod_admin_create`;
const GAME_TWOD_THREED_TWOD_ADMIN_UPDATE_API = `${SERVER_API}/v1/games/twod_admin_update`;

const GAME_TWOD_THREED_TWOD_HOLIDAY_LIST_API = `${SERVER_API}/v1/games/holiday_list`;
const GAME_TWOD_THREED_TWOD_HOLIDAY_CREATE_API = `${SERVER_API}/v1/games/holiday_create`;
const GAME_TWOD_THREED_TWOD_HOLIDAY_UPDATE_API = `${SERVER_API}/v1/games/holiday_upate`;

const GAME_TWOD_AGENT_ONGOING_LIST_API = `${SERVER_API}/v1/games/agent_twod_list`;
const GAME_TWOD_AGENT_UPDATE_LIST_API = `${SERVER_API}/v1/games/agent_twod_update`;

const GAME_THREED_AGENT_ONGOING_LIST_API = `${SERVER_API}/v1/games/agent_threed_list`;
const GAME_THREED_AGENT_UPDATE_LIST_API = `${SERVER_API}/v1/games/agent_threed_update`;
const GAME_THREED_FINISH_API = `${SERVER_API}/v1/games/threed_finish`;

const GAME_TWOD_USER_LOGS_API = `${SERVER_API}/v1/games/user_twod_logs`;
const GAME_THREED_USER_LOGS_API = `${SERVER_API}/v1/games/user_threed_logs`;

const GAME_BALL_AGENT_LIST_API = `${SERVER_API}/v1/games/ball_agent_list`;
const GAME_BALL_AGENT_CREATE_API = `${SERVER_API}/v1/games/ball_agent_create`;
const GAME_BALL_AGENT_UPDATE_API = `${SERVER_API}/v1/games/ball_agent_update`;

const GAME_BALL_TEAM_LIST_API = `${SERVER_API}/v1/games/ball_team_list`;
const GAME_BALL_TEAM_CREATE_API = `${SERVER_API}/v1/games/ball_team_create`;
const GAME_BALL_TEAM_UPDATE_API = `${SERVER_API}/v1/games/ball_team_update`;
const GAME_BALL_TEAM_DELETE_API = `${SERVER_API}/v1/games/ball_team_delete`;

const GAME_BALL_LEAGUE_LIST_API = `${SERVER_API}/v1/games/ball_league_list`;
const GAME_BALL_LEAGUE_CREATE_API = `${SERVER_API}/v1/games/ball_league_create`;
const GAME_BALL_LEAGUE_UPDATE_API = `${SERVER_API}/v1/games/ball_league_update`;
const GAME_BALL_LEAGUE_DELETE_API = `${SERVER_API}/v1/games/ball_league_delete`;

const GAME_BALL_MATCH_LIST_API = `${SERVER_API}/v1/games/ball_match_list`;
const GAME_BALL_MATCH_CREATE_API = `${SERVER_API}/v1/games/ball_match_create`;
const GAME_BALL_MATCH_UPDATE_API = `${SERVER_API}/v1/games/ball_match_update`;
const GAME_BALL_MATCH_DELETE_API = `${SERVER_API}/v1/games/ball_match_delete`;

const GAME_BALL_WIN_CUT_LIST_API = `${SERVER_API}/v1/games/ball_win_cut`;
const GAME_BALL_WIN_CUT_CREATE_API = `${SERVER_API}/v1/games/ball_win_cut_create`;
const GAME_BALL_WIN_CUT_UPDATE_API = `${SERVER_API}/v1/games/ball_win_cut_edit`;
const GAME_bALL_MATCH_FINISH_API = `${SERVER_API}/v1/games/ball_match_finish`;

const GAME_SHAN_AGENT_LIST_API = `${SERVER_API}/v1/shan_komee/list`;
const GAME_SHAN_AGENT_CREATE_API = `${SERVER_API}/v1/shan_komee/create_agent`;
const GAME_SHAN_AGENT_UPDATE_API = `${SERVER_API}/v1/shan_komee/remove_agent`;
const GAME_SHAN_USER_LOG_API = `${SERVER_API}/v1/shan_komee/user_logs`;

const GAME_PP_AGENT_LIST_API = `${SERVER_API}/v1/games/pp_agent_list`;
const GAME_PP_AGENT_CREATE_API = `${SERVER_API}/v1/games/pp_agent_create`;
const GAME_PP_AGENT_UPDATE_API = `${SERVER_API}/v1/games/pp_agent_update`;
const GAME_PP_GAME_LIST_API = `${SERVER_API}/v1/games/pp_list`;
const GAME_PP_USER_LOG_API = `${SERVER_API}/v1/games/pp_user_logs`;

export {
  SERVER_API,
  // files
  UPLOAD_API,
  // cms User
  CMS_LOGIN_API,
  CMS_USER_CREATE,
  CMS_USER_GET_LIST,
  CMS_USER_LOGIN_NAME_CHECK,
  CMS_USER_DELETE,
  CMS_USER_EDIT_API,
  CMS_USER_UNLOCK_API,
  CMS_USER_PASSWORD_RESET,
  CMS_PASSWORD_RESET,
  CMS_THEME_RESET_API,
  // access level
  CMS_ACCESS_LEVEL_API,
  CMS_SCCESS_LEVEL_CREATE_API,
  CMS_ACCESS_LEVEL_UPDATE_API,
  CMS_ACCESS_LEVEL_DELETE_API,
  // cms agent
  CMS_AGENT_LOGIN_API,
  CMS_AGENT_BLOCK_API,
  CMS_AGENT_CREATE_API,
  CMS_AGENT_CREDENTIAL_RESET_API,
  CMS_AGENT_AGENT_CODE_CHECK_API,
  CMS_AGENT_DELETE_API,
  CMS_AGENT_EDIT_API,
  CMS_AGENT_LIST_API,
  CMS_AGENT_LOGIN_NAME_CHECK_API,
  CMS_AGENT_PASSWORD_RESET_API,
  CMS_AGENT_THEME_CHANGE_API,
  CMS_AGENT_UNLOCK_API,
  // cms payment
  CMS_PAYMENT_METHOD_CREATE_API,
  CMS_PAYMENT_METHOD_DELETE_API,
  CMS_PAYMENT_METHOD_LIST_API,
  // transaction
  CMS_TRANSACTION_CREATE_API,
  CMS_TRANSACTION_LIST_API,
  // agent promotion
  AGENT_CREATE_USER_PROMO_API,
  AGENT_PROMOTION_CREATE_API,
  AGENT_PROMOTION_DELETE_API,
  AGENT_PROMOTION_EDIT_API,
  AGENT_PROMOTION_LIST_API,
  // agent payment
  AGENT_PAYMENT_METHOD_CREATE_API,
  AGENT_PAYMENT_METHOD_DELETE_API,
  AGENT_PAYMENT_METHOD_EDIT_API,
  AGENT_PAYMENT_METHOD_LIST_API,
  // agent promotion special
  AGENT_PROMOTION_SPECIAL_CREATE_API,
  AGENT_PROMOTION_SPECIAL_LIST_API,
  // agent subscription
  AGENT_SUBSCRIPTION_LIST_API,
  // utility avatar
  UTILITY_AVATAR_CREATE_API,
  UTILITY_AVATAR_DELETE_API,
  UTILITY_AVATAR_LIST_API,
  // agent transaction
  AGENT_TRANSACTION_LIST_API,
  AGENT_TRANSACTION_NOTI_API,
  AGENT_TRANSACTION_USER_API,
  AGENT_TRANSACTION_CREATE_API,
  //users
  USER_CREATE_API,
  USER_GET_LIT_API,
  USER_LOGIN_NAME_CHECK_APi,
  USER_EDIT_API,
  USER_PASSWORD_RESET_API,
  USER_PAYMENT_COUNTER_RESET_API,
  USER_UNLOCK_API,
  USER_DELETE_API,
  USER_TRANSACTION_UPDATE,
  // games
  GAME_AGENT_GET_LIST,
  // twod threed
  GAME_TWOD_THREED_AGENT_CREATE_API,
  GAME_TWOD_THREED_AGENT_LIST_API,
  GAME_TWOD_THREED_AGENT_UPDATE_API,
  GAME_TWOD_THREED_THREED_ONGOING_CREATE_API,
  GAME_TWOD_THREED_THREED_ONGOING_DELETE_API,
  GAME_TWOD_THREED_THREED_ONGOING_LIST_API,
  GAME_TWOD_THREED_THREED_ONGOING_UPDATE_API,
  GAME_TWOD_THREED_TWOD_ADMIN_CREATE_API,
  GAME_TWOD_THREED_TWOD_ADMIN_LIST_API,
  GAME_TWOD_THREED_TWOD_ADMIN_UPDATE_API,
  GAME_TWOD_THREED_TWOD_HOLIDAY_CREATE_API,
  GAME_TWOD_THREED_TWOD_HOLIDAY_LIST_API,
  GAME_TWOD_THREED_TWOD_HOLIDAY_UPDATE_API,
  GAME_TWOD_THREED_THREED_USER_LIST_APi,
  GAME_TWOD_THREED_TWOD_USER_LIST_APi,
  GAME_TWOD_AGENT_ONGOING_LIST_API,
  GAME_TWOD_AGENT_UPDATE_LIST_API,
  GAME_THREED_AGENT_ONGOING_LIST_API,
  GAME_THREED_AGENT_UPDATE_LIST_API,
  GAME_THREED_FINISH_API,
  GAME_TWOD_USER_LOGS_API,
  GAME_THREED_USER_LOGS_API,
  // ball
  GAME_BALL_AGENT_CREATE_API,
  GAME_BALL_AGENT_LIST_API,
  GAME_BALL_AGENT_UPDATE_API,
  GAME_BALL_TEAM_CREATE_API,
  GAME_BALL_TEAM_DELETE_API,
  GAME_BALL_TEAM_LIST_API,
  GAME_BALL_TEAM_UPDATE_API,
  GAME_BALL_LEAGUE_CREATE_API,
  GAME_BALL_LEAGUE_DELETE_API,
  GAME_BALL_LEAGUE_LIST_API,
  GAME_BALL_LEAGUE_UPDATE_API,
  GAME_BALL_MATCH_CREATE_API,
  GAME_BALL_MATCH_DELETE_API,
  GAME_BALL_MATCH_LIST_API,
  GAME_BALL_MATCH_UPDATE_API,
  GAME_BALL_WIN_CUT_CREATE_API,
  GAME_BALL_WIN_CUT_LIST_API,
  GAME_BALL_WIN_CUT_UPDATE_API,
  GAME_bALL_MATCH_FINISH_API,
  // shan
  GAME_SHAN_AGENT_CREATE_API,
  GAME_SHAN_AGENT_LIST_API,
  GAME_SHAN_AGENT_UPDATE_API,
  GAME_SHAN_USER_LOG_API,
  //pp
  GAME_PP_AGENT_CREATE_API,
  GAME_PP_AGENT_LIST_API,
  GAME_PP_AGENT_UPDATE_API,
  GAME_PP_GAME_LIST_API,
  GAME_PP_USER_LOG_API,
};
