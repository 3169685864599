import { Col, Row } from "antd";

export default function ShanGameDetailUi({ data }) {
  console.log(data);

  return (
    <Row>
      <Col>detail</Col>
    </Row>
  );
}
