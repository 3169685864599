import { ConfigProvider, Layout, Skeleton, theme } from "antd";
import { useSelector } from "react-redux";
import LoginPage from "./pages/login";
import MainLayout from "./layout/layout";
import { HashRouter } from "react-router-dom";
import React from "react";

function App() {
  const { id, select_theme } = useSelector((state) => state.user);

  return (
    <HashRouter>
      <React.Suspense fallback={<Skeleton active />}>
        <ConfigProvider
          theme={{
            algorithm:
            select_theme === "dark"
                ? theme.darkAlgorithm
                : theme.compactAlgorithm,
          }}
        >
          {id ? (
            <MainLayout />
          ) : (
            <Layout style={{ height: "100vh" }}>
              <LoginPage />
            </Layout>
          )}
        </ConfigProvider>
      </React.Suspense>
    </HashRouter>
  );
}

export default App;
