import { useEffect } from "react";
import { useState } from "react";
import { GetDataListFromServer } from "../../lib/apis";
import {
  GAME_THREED_USER_LOGS_API
} from "../../lib/config";
import { QUERY_USER_TWOD_LOGS } from "../../lib/query_objects";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  message,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import { moneyFormat, queryHandler } from "../../lib/globle_functions";
import dayjs from "dayjs";
import moment from "moment";

const { Text } = Typography;

export default function ThreedUserLogs({ data, closeDrawer }) {
  const { access_token, ui_size } = useSelector((state) => state.user);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    target_time: "",
    win_lose: "",
    is_finish: "",
    target_date: "",
  });
  const [paging, setPaging] = useState({
    page_number: 1,
    per_page: 10,
    total: 0,
  });

  async function getList(page_number, per_page, updateFilter) {
    await GetDataListFromServer(
      `${GAME_THREED_USER_LOGS_API}/${data.id}`,
      queryHandler(updateFilter ? updateFilter : filter, QUERY_USER_TWOD_LOGS, {
        page_number,
        per_page,
      }),
      access_token
    )
      .then(({ data: { data: dataList, total } }) => {
        updateFilter && setFilter(updateFilter);
        setPaging({ ...paging, total, page_number, per_page });
        setList(dataList);
        setLoading(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.error || "Connection Error");
        setLoading(false);
      });
  }

  function clearTransaction() {
    getList(paging.page_number, paging.per_page, {
      target_time: "",
      win_lose: "",
      is_finish: "",
      target_date: "",
    });
  }

  useEffect(() => {
    getList(paging.page_number, paging.per_page);
  }, []);

  const columns = [
    {
      title: "Date",
      dataIndex: ["threed_ongoing", "threed_round", "target_date"],
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Bit Number",
      dataIndex: "bit_number",
    },
    {
      title: "Bit Amount",
      dataIndex: "bit_amount",
      render: (bit_amount) => moneyFormat(bit_amount),
    },
    {
      title: "Win Number",
      dataIndex: "win_number",
      render: (_, record) => (record.is_finish ? record.win_number : "---"),
    },
    {
      title: "Is Win",
      dataIndex: "win_lose",
      render: (win_lose) => (win_lose ? <Tag>Win</Tag> : <Tag>Lose</Tag>),
    },
    {
      title: "Win Amount",
      dataIndex: "win_amount",
      render: (win_amount) => moneyFormat(win_amount),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          <Row gutter={[12, 2]}>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Date</Text>
                </Col>
                <Col span={12}>
                  <DatePicker
                    allowClear={false}
                    style={{ with: "100%" }}
                    format={"YYYY-MM-DD"}
                    value={
                      filter.target_date !== ""
                        ? dayjs(filter.target_date, "YYYY-MM-DD")
                        : null
                    }
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        target_date: e,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row>
                <Col span={12}>
                  <Text>Target Time</Text>
                </Col>
                <Col span={12}>
                  <Select
                    value={filter.target_time}
                    style={{ width: "100%" }}
                    options={[
                      { label: "11:00:00", value: "11:00:00" },
                      { label: "12:01:00", value: "12:01:00" },
                      { label: "15:00:00", value: "15:00:00" },
                      { label: "16:30:00", value: "16:30:00" },
                    ]}
                    onChange={(e) => setFilter({ ...filter, target_time: e })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={12} justify={"end"} style={{ width: "100%" }}>
                <Col>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => clearTransaction()}
                  >
                    Clear
                  </Button>
                </Col>
                <Col>
                  <Button
                    loading={loading}
                    type="primary"
                    onClick={() => {
                      getList(paging.page_number, paging.per_page);
                    }}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Table
            size={ui_size}
            style={{ marginTop: "12px" }}
            columns={columns}
            rowKey={(record) => record.id}
            pagination={{
              total: paging.total,
              defaultCurrent: 1,
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 30, 50],
              onChange: (pageNumber, perPage) => {
                getList(pageNumber, perPage);
              },
            }}
            dataSource={list}
            loading={loading}
          />
        </Col>
      </Row>
    </Spin>
  );
}
