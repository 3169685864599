import { BulbFilled, BulbOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logOff, updateUser } from "../store/user";
import { useState } from "react";
import {
  CMS_AGENT_PASSWORD_RESET_API,
  CMS_AGENT_THEME_CHANGE_API,
  CMS_PASSWORD_RESET,
  CMS_THEME_RESET_API,
} from "../lib/config";
import { UpdateDateOnServer, UpdateSingleDataOnServer } from "../lib/apis";
import AgentSubscriptionPage from "./agent_point_subscription";
const { Text } = Typography;

export default function SettingPage() {
  const user = useSelector((state) => state.user);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [themeLoading, setThemeLoading] = useState(false);
  const is_agent = window.location.href.includes("agent");

  async function themeChanger(value, type) {
    let requestObject = false;

    switch (true) {
      case type === "theme":
        if (value === "dark" || value === "white") {
          requestObject = {
            theme: value,
          };
        }
        break;
      case type === "ui_size":
        if (value === "small" || value === "normal" || value === "large") {
          requestObject = {
            ui_size: value,
          };
        }
        break;
      default:
        return;
        break;
    }

    setThemeLoading(true);
    await UpdateDateOnServer(
      is_agent ? CMS_AGENT_THEME_CHANGE_API : CMS_THEME_RESET_API,
      user.id,
      requestObject,
      user.access_token
    )
      .then(() => {
        setThemeLoading(false);
        dispatch(
          updateUser(
            type === "theme"
              ? { ...user, select_theme: value }
              : { ...user, ui_size: value }
          )
        );
      })
      .catch((error) => {
        setThemeLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  async function submitHandler(value) {
    setLoading(true);
    await UpdateSingleDataOnServer(
      is_agent ? CMS_AGENT_PASSWORD_RESET_API : CMS_PASSWORD_RESET,
      value.password,
      user.access_token
    )
      .then(() => {
        setLoading(false);
        dispatch(logOff());
      })
      .catch((error) => {
        setLoading(false);
        message.error(error?.response?.data?.error || "Connection Error");
      });
  }

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={12}>
          <Card size={user.ui_size} title="User Profile">
            <Row gutter={[24, 16]}>
              <Col span={8}>Current Points</Col>
              <Col span={16}>{user.agent_points}</Col>
              <Col span={8}>Agent Code</Col>
              <Col span={16}>
                <Text copyable>{user.agent_code}</Text>
              </Col>
              <Col span={8}>
                <Text>Name</Text>
              </Col>
              <Col span={16}>
                <Text>{user.name}</Text>
              </Col>
              <Col span={8}>
                <Text>Login Name</Text>
              </Col>
              <Col span={16}>
                <Text>{user.login_name}</Text>
              </Col>
              <Col span={8}>
                <Text>Email</Text>
              </Col>
              <Col span={16}>
                <Text>{user.email}</Text>
              </Col>
              <Col span={8}>
                <Text>Phone</Text>
              </Col>
              <Col span={16}>
                <Text>{user.phone}</Text>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
              <Col>
                <Button type="primary" onClick={() => setShowModel(true)}>
                  Password Reset
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card size={user.ui_size} title="System Settings">
            <Row gutter={[24, 16]}>
              <Col span={8}>
                <Text>Theme</Text>
              </Col>
              <Col span={16}>
                <Row gutter={16}>
                  <Col>
                    <Button
                      size={user.ui_size}
                      icon={<BulbOutlined />}
                      loading={themeLoading}
                      disabled={user.select_theme === "dark"}
                      onClick={() => themeChanger("dark", "theme")}
                    >
                      Dark
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      size={user.ui_size}
                      icon={<BulbFilled />}
                      loading={themeLoading}
                      disabled={user.select_theme !== "dark"}
                      onClick={() => themeChanger("white", "theme")}
                    >
                      White
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                <Text>Ui Size</Text>
              </Col>
              <Col span={16}>
                <Row gutter={16} align={"middle"}>
                  <Col>
                    <Button
                      disabled={user.ui_size === "small"}
                      size="small"
                      loading={themeLoading}
                      onClick={() => themeChanger("small", "ui_size")}
                    >
                      Small
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      disabled={user.ui_size === "normal"}
                      size="normal"
                      loading={themeLoading}
                      onClick={() => themeChanger("normal", "ui_size")}
                    >
                      Normal
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      disabled={user.ui_size === "large"}
                      size="large"
                      loading={themeLoading}
                      onClick={() => themeChanger("large", "ui_size")}
                    >
                      Large
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          {is_agent ? (
            <Text style={{ fontSize: "1.3rem" }}>Subscriptions</Text>
          ) : (
            <div></div>
          )}
        </Col>
        <Col span={24}>
          {is_agent ? <AgentSubscriptionPage /> : <div></div>}
        </Col>
      </Row>
      <Modal
        size={user.ui_size}
        footer={null}
        title="Password Reset"
        open={showModel}
        onCancel={() => setShowModel(false)}
        destroyOnClose
      >
        <Form
          form={form}
          wrapperCol={{ span: 16 }}
          labelCol={{ span: 8 }}
          name="own_password_reset"
          style={{ marginTop: "32px" }}
          onFinish={submitHandler}
        >
          <Form.Item
            name="password"
            label="Password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify={"end"}>
            <Col>
              <Form.Item>
                <Button loading={loading} type="primary" htmlType="submit">
                  Confirm
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
